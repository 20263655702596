import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { Layout } from "../../components/layout"
import { parseDate, formatDateInterval } from "../../data/utils"
import data, { SessionsSector } from "../../data/detection"

interface State {
  sessionsSectors: SessionsSector[]
}

class Detection20212022Page extends React.Component<unknown, State> {
  constructor(props: unknown, context: unknown) {
    super(props, context)
    this.state = {
      sessionsSectors: data,
    }
  }

  render(): JSX.Element {
    const { sessionsSectors } = this.state

    return (
      <Layout>
        <Helmet title="Stages détection 2021-2022" />
        <div className="container">
          <div className="row mt-3">
            <div className="secondary-col">
              <div className="dropdown">
                <button
                  className="btn btn-primary btn-lg btn-block dropdown-toggle"
                  type="button"
                  id="detection-reservation"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  S&apos;inscrire
                </button>
                <div className="dropdown-menu" aria-labelledby="detection-reservation">
                  {sessionsSectors.map((sessionsSector, index) => {
                    let result = []

                    if (index > 0) {
                      result.push(<div className="dropdown-divider" />)
                    }

                    result.push(<h6 className="dropdown-header">{sessionsSector.id}</h6>)
                    result = result.concat(
                      sessionsSector.sessions.map(session =>
                        session.shopId ? (
                          <Link to={`/formations/detection-2021-2022/${session.id}/`} className="dropdown-item">
                            {session.name}
                          </Link>
                        ) : (
                          <></>
                        )
                      )
                    )

                    return result
                  })}
                </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-9 my-3">
              <h1>Stages détection 2021-2022</h1>

              <h2>Description</h2>
              <p>
                Cette détection régionale "jeunes" a pour but de suivre en collaboration avec les entraîneurs des clubs ETAF-R les 40 meilleurs jeunes selon un classement régional toutes catégories (Poussins, Benjamins, Minimes, Cadets, et Juniors en arcs classique et à poulies).
              </p>

              <h2>Les objectifs</h2>
              <p>
                <ol>
                  <li>
                    Préparer l&apos;accès à la filière sportive olympique, aux championnats de France individuels et par équipes de clubs.
                  </li>
                  <li>
                    Sensibiliser les tireurs à l&apos;opportunité d&apos;accéder à la filière du haut niveau (Pôles).
                  </li>
                  <li>
                    Accompagner l’entraîneur de club et les archers de niveau régional tout au long de l’année sportive.
                  </li>
                </ol>
              </p>

              <h2>Sessions 2021-2022</h2>

              <p>Le stage débutera à 9h et se terminera à 17h. <i>Tarif spécial 2021/2022 50% moins cher avec le plan de relance</i></p>

              <table className="table mt-3">
                <thead>
                  <tr>
                    <th>Session</th>
                    <th>Dates</th>
                    <th>Tarif</th>
                    <th>-</th>
                  </tr>
                </thead>
                <tbody>
                  {sessionsSectors.map(sessionsSector => (
                    <>
                      <tr>
                        <th className="text-center" colSpan={4}>
                          Secteur {sessionsSector.id}
                        </th>
                      </tr>
                      {sessionsSector.sessions.map(session => (
                        <tr>
                          <td>
                            <b>{session.name}</b>
                            <br />
                            {session.place.map((placeRow, index) => {
                              const result = []

                              if (index > 0) {
                                result.push(<br />)
                              }

                              result.push(<small>{placeRow}</small>)
                              return result
                            })}
                          </td>
                          <td>
                            {session.dateStart && session.dateEnd ? <small>{formatDateInterval(parseDate(session.dateStart), parseDate(session.dateEnd))}</small> : <small>{session.dateStart}</small>}
                          </td>
                          <td>
                            <i>{session.price}€</i>
                          </td>
                          <td className="text-right">
                            {session.shopId ? (
                              <Link to={`/formations/detection-2021-2022/${session.id}/`} className="btn btn-primary">
                                S&apos;inscrire
                              </Link>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  ))}
                </tbody>
              </table>

              <h3>Suivi pédagogique</h3>

              <p>
                Est assuré par le CT en charge du développement des performances sportives en collaboration avec les membres de l’ETR ainsi que les entraîneurs des clubs concernés par la sélection des archers.
              </p>

              <h3>Suivi pédagogique</h3>

              <p>
                3 stages de 3 jours (Fin août, Noël, Pâques) alternés avec 8 suivis lors d’une demi-journée en club.
              </p>

              <h3>Critères de participation</h3>

              <p>
                La sélection se fera selon le classement régional figurant au classement national TAE de l'année A-1 toutes catégories, les 40 premiers seront invités à y participer.
              </p>

              <h3>Composition d'un groupe</h3>

              <p>
                Les archers seront invités nominativement en début de saison sportive et devront répondre sous 15 jours à cette invitation.
                Une copie de cette invitation sera adressée à leurs présidents de clubs et entraîneurs.
              </p>

              <h3>Conditions de maintien dans la filière</h3>

              <p>
                <b>Assiduité, motivation, investissement.</b>
                <br />
                L&apos;archer doit s&apos;entraîner au moins 2 à 3 fois par semaine en respectant les consignes du cadre référent.
              </p>

              <h3>Résumé du contenu du stage</h3>

              <p>
                Clef de voûte du contenu des interventions, les 5 grands thèmes des stages sont :
                <ol>
                  <li>technique</li>
                  <li>physique</li>
                  <li>matériel</li>
                  <li>mental</li>
                  <li>planification</li>
                </ol>
              </p>

              <h3>Information des comités départementaux</h3>

              <p>
                la liste des archers participant aux stages de détection sera communiquée à chaque comité départemental concerné, lequel
                pourra - selon ses possibilités et ses objectifs - réaliser un suivi mensuel avec ses mêmes archers en lien avec le suivi du
                stage régional et compléter les groupes avec d'autres archers compétiteurs.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Detection20212022Page
