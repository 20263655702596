export type Sector = "Grand Est" | "Alsace" | "Lorraine" | "Champagne-Ardenne"

export interface Session {
  id: string
  shopId: string | null
  name: string
  place: string[]
  dateStart: string
  dateEnd?: string
  tooltip?: string
  price: number
}

export interface SessionsSector {
  id: Sector
  sessions: Session[]
}

const data: SessionsSector[] = [
  {
    id: "Grand Est",
    sessions: [
      {
        id: "vittel-aout-2022",
        shopId: "cs1-stage-de-detection-vittel-aout-2022",
        name: "Vittel - Août 2022",
        place: ["88800 Vittel"],
        dateStart: "2022-08-24",
        dateEnd: "2022-08-26",
        price: 52,
      },
      {
        id: "csra-decembre-2022",
        shopId: "brouillon-cs1-stage-de-detection-2",
        name: "CSRA - Décembre 2022",
        place: ["Centre Sportif Régional", "5 rue des Frères Lumière", "68200 Mulhouse"],
        dateStart: "2022-12-19",
        dateEnd: "2022-12-21",
        price: 52,
      },
      {
        id: "lac-du-der-avril-2023",
        shopId: "brouillon-cs1-stage-de-detection-avril-vittel-2",
        name: "Lac du Der - Avril 2023",
        place: ["Lac du Der", "Presqu Ile de Rougemer", "51290 Giffaumont-Champaubert"],
        dateStart: "2023-04-21",
        dateEnd: "2023-04-23",
        price: 52,
      },
    ],
  },
]

export default data
